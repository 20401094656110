import PropTypes from 'prop-types'
import React from 'react'
import img_2048_wip from '../images/IMG_0982 Large.jpeg'
import img_4298_jewellery_stands from '../images/IMG_4298.jpeg'
import img_2000_jewellery_stand from '../images/IMG_2000 Large.jpeg'
import img_1913_vases from '../images/IMG_1913 Large.jpeg'
import img_1916_small_vases from '../images/IMG_1916 Large.jpeg'
import img_1921_cracked_style_vase_front from '../images/IMG_1921 Large.jpeg'
import img_1932_cracked_style_vase_back from '../images/IMG_1932 Large.jpeg'
import img_1934_incense_holders from '../images/IMG_1934 Large.jpeg'
import img_1952_tall_incense_holders_detail from '../images/IMG_1952 Large.jpeg'
import img_1942_tall_incense_holders from '../images/IMG_1942 Large.jpeg'
import img_1968_ceramic_stones from '../images/IMG_1968 Large.jpeg'
import img_1986_incense_holder_insert from '../images/IMG_1986 Large.jpeg'
import img_1988_incense_holder_insert from '../images/IMG_1988 Large.jpeg'
import img_1989_bowl from '../images/IMG_1989 Large.jpeg'
import img_1991_incense_holder_insert from '../images/IMG_1991 Large.jpeg'
import img_1996_bracelet_stand from '../images/IMG_1996 Large.jpeg'
import img_1993_vase from '../images/IMG_1993 Large.jpeg'
import img_2005_tall_jewellery_stand from '../images/IMG_2005 Large.jpeg'
import img_2010_jewellery_stand from '../images/IMG_2010 Large.jpeg'
import img_2011_jewellery_stand from '../images/IMG_2011 Large.jpeg'
import img_2020_pot from '../images/IMG_2020 Large.jpeg'
import img_2048_sculpture from '../images/IMG_2048 Large.jpeg'
import img_2066_pot from '../images/IMG_2066 Large.jpeg'
import img_7506_sculpture from '../images/IMG_7506.jpeg'
import img_9308_golden_bust from '../images/img_9308 Large.jpeg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <p>
          Welcome to Roots and Sculpture. We specialize in creating high-end art, consumer ceramics, crafts workshops, murals, and paintings. We believe that art is a powerful means of self-expression, and our team of talented artists and designers is dedicated to creating unique, beautiful, and functional works that inspire and delight. Our workshops and classes offer hands-on learning experiences that encourage creativity and skill-building, and our murals and paintings bring beauty and color to any space. Whether you're a collector, a creative enthusiast, or simply looking to add something special to your home or business, we invite you to explore our collection and discover the joy and inspiration of art.
          </p>
          {close}
        </article>

        <article
          id="gallery"
          className={`${this.props.article === 'gallery' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Gallery</h2>
          <span className="image main">
            <img src={img_9308_golden_bust} alt="" />
          </span>
          <p>
            Golden
          </p>
          <span className="image main">
            <img src={img_2048_wip} alt="" />
          </span>
          <p>
            Work in progress
          </p>
          <span className="image main">
            <img src={img_1968_ceramic_stones} alt="" />
          </span>
          <p>
            Ceramic stones
          </p>
          <span className="image main">
            <img src={img_7506_sculpture} alt="" />
          </span>
          <p>
            Two sides
          </p>
          {close}
        </article>

        <article
          id="shop"
          className={`${this.props.article === 'shop' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Shop</h2>
          <span className="image main">
            <img src={img_4298_jewellery_stands} alt="" />
          </span>
          <p>
            Jewellery stands $65 - $325
          </p>
          <span className="image main">
            <img src={img_2011_jewellery_stand} alt="" />
          </span>
          <p>
            Jewellery stand $85
          </p>
          <span className="image main">
            <img src={img_2010_jewellery_stand} alt="" />
          </span>
          <p>
            Small bracelet and necklace stand set, midnight blue $95
          </p>
          <span className="image main">
            <img src={img_2005_tall_jewellery_stand} alt="" />
          </span>
          <p>
            XL necklace stand $225 <br />Necklace $295
          </p>
          <span className="image main">
            <img src={img_1996_bracelet_stand} alt="" />
          </span>
          <p>
            Small bracelet stand $85
          </p>
          <span className="image main">
            <img src={img_2000_jewellery_stand} alt="" />
          </span>
          <p>
            Small necklace stand, grey $95
          </p>
          <span className="image main">
            <img src={img_2048_sculpture} alt="" />
          </span>
          <p>
            Sculpture $625
          </p>
          <span className="image main">
            <img src={img_1993_vase} alt="" />
          </span>
          <p>
            Ribbed vase $125
          </p>
          <span className="image main">
            <img src={img_2066_pot} alt="" />
          </span>
          <p>
            Vase $120
          </p>
          <span className="image main">
            <img src={img_2020_pot} alt="" />
          </span>
          <p>
            Vase $120
          </p>
          <span className="image main">
            <img src={img_1986_incense_holder_insert} alt="" />
          </span>
          <span className="image main">
            <img src={img_1988_incense_holder_insert} alt="" />
          </span>
          <span className="image main">
            <img src={img_1991_incense_holder_insert} alt="" />
          </span>
          <p>
            Removable insence holder insert $35
          </p>
          <span className="image main">
            <img src={img_1989_bowl} alt="" />
          </span>
          <p>
            Bowl $55
          </p>
          <span className="image main">
            <img src={img_1934_incense_holders} alt="" />
          </span>
          <p>
            Mini insence holders $30/pc
          </p>
          <span className="image main">
            <img src={img_1921_cracked_style_vase_front} alt="" />
            Front
          </span>
          <span className="image main">
            <img src={img_1932_cracked_style_vase_back} alt="" />
            Back
          </span>
          <p>
            Cracked style vase $120
          </p>
          <span className="image main">
            <img src={img_1952_tall_incense_holders_detail} alt="" />
          </span>
          <span className="image main">
            <img src={img_1942_tall_incense_holders} alt="" />
          </span>
          <p>
            Tall insence holders $40/pc
          </p>
          <span className="image main">
            <img src={img_1916_small_vases} alt="" />
          </span>
          <span className="image main">
            <img src={img_1913_vases} alt="" />
          </span>
          <p>
            Big vase $100<br/>
            Small vase $65
          </p>
          
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form method="post" action="https://rootsandsculpture-mailer.onrender.com/contact">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a href="https://www.instagram.com/rootsandsculpture" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
